
/*styles for view menu*/
.view-items {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
    color: #06400e;
}

.view-items .btn-new-item {
    padding: 10px 5px 10px 5px;
}

.view-items .btn-new-item button{
    background-color: #04500e;
    color: #ebb267;
    border: #06400e;
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
}

.view-items .btn-new-item button:hover {
    background-color: #06400e;
}

.menu {
    margin-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}

.menu .image-group {
    margin: 10px 30px 30px 30px;
}

.menu .image-group img {
    height: 200px;
}

.menu .image-group h5{
    padding-top: 20px;
}

.menu .image-group h6 {
    margin-bottom: 10px;
}

.menu .image-group button {
    background-color: rgb(184, 6, 6);
    color: #f0c082;
    border: rgb(184, 6, 6);
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
}

.menu .image-group button:hover {
    background-color: rgb(150, 5, 5);
}



