
/*styles for Home content*/
.row img {
    object-fit: cover; /* Set object-fit to cover */
    width: 100%;
}

.col-sm-12.col-md-8 {
    padding: 0;
}

.descrip {
    color: #cf964c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}
.descrip.des {
    margin-right: 2px;
}
.descrip.des1 {
    background-color: #2b0507;
}

.descrip.des2 {
    background-color: #070116;
}

.descrip.des3 {
    background-color: #3a190a;
}

.descrip h1 {
    margin-bottom: 20px;
    padding: 10px;
    
}

.p-des {
    font-size: 15px;
}

.line {
    display: block;
    border-bottom: 2px solid #cf964c;
    width: 250px;
    margin-top: 30px;
}

.h1-title {
    position: relative;
}
  
.descrip h1 .h1-title::before,
.descrip h1 .h1-title::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #cf964c; /* Color of the lines for active item */
    left: 0;
    transform: scaleX(1);
    
}

.descrip h1 .h1-title::before {
    top: -2px;
}

.descrip h1 .h1-title::after {
    bottom: -2px;
}

.descrip p {
    padding: 10px;
}