.view-messages {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
    color: #06400e;
}

.view-messages h2 {
    padding-bottom: 20px;
}

.view-messages table {
    width: 100%;
    table-layout: fixed;
    text-align: left;
  }

.view-messages th, .view-messages td {
    white-space: normal;
    overflow-wrap: break-word;
    padding: 8px;
    border: 1px solid #06400e;
  }

  .view-messages th:nth-child(1),
  .view-messages th:nth-child(2),
  .view-messages td:nth-child(1),
  .view-messages td:nth-child(2) {
        width: 14%; /* Adjust the width as needed */
  }

  .view-messages th:nth-child(3),
  .view-messages td:nth-child(3) {
    width: 8%;
  }

  .view-messages th:nth-child(4),
  .view-messages th:nth-child(5),
  .view-messages td:nth-child(4),
  .view-messages td:nth-child(5) {
    width: 24%;
  }

  .view-messages th:nth-child(6),
  .view-messages td:nth-child(6) {
    width: 10%;
  }

  .view-messages th:last-child,
  .view-messages td:last-child {
    width: 6%;
    border: none;
  }

  .view-messages .btn-reply {
    background-color: #263ca1;
    color: #f0c082;
    border: #263ca1;
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
  }

  .view-messages .btn-reply:hover {
    background-color: #122681;
  }


  /*styles for replyMessage*/
  .reply {
    width: 100%;
    margin: 0 auto;
    padding: 40px 20px 30px 20px;
    font-family: 'Times New Roman', Times, serif;
    color: #06400e;
  }