/*styles for View Team*/
.view-team {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
    color: #06400e;
}

.view-team .btn-new-member {
    padding: 10px 5px 10px 5px;
}

.view-team .btn-new-member button{
    background-color: #04500e;
    color: #ebb267;
    border: #06400e;
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
}

.view-team .btn-new-member button:hover {
    background-color: #06400e;
}

.team {
    margin-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}

.team .image-group {
    margin: 10px 30px 30px 30px;
}

.team .image-group img {
    height: 300px;
}

.team .image-group h5{
    padding-top: 20px;
}

.team .image-group h6 {
    margin-bottom: 10px;
}

.team .image-group .button-group {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.team .image-group .button-group button {
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
    margin: auto 10px auto 10px;
}

.team .image-group .button-group .btn-remove {
    background-color: rgb(184, 6, 6);
    color: #f0c082;
    border: rgb(184, 6, 6);
}

.team .image-group .button-group .btn-remove:hover {
    background-color: rgb(150, 5, 5);
}

.team .image-group .button-group .btn-edit {
    background-color: rgb(35, 6, 204);
    color: #f0c082;
    border: rgb(35, 6, 204);
}

.team .image-group .button-group .btn-edit:hover {
    background-color: rgb(30, 5, 172);
}

