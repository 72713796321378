/* Remove padding from Bootstrap container */
.container-fluid {
    padding: 0px !important;
    display: flex;
    flex-direction: column; 
    min-height: 100vh;
}

.main {
    flex-grow: 1;
    background-color:rgb(218, 215, 211);
}

.main h2 {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  color: #06400e;
  padding-top: 30px;
  margin-bottom: 20px;
}

.testing {
  text-align: center;
  color: bisque;
}

.testing h6 {
  font-style: italic;
}

.testing table th,
.testing table td {
  padding: 3px 5px 3px 5px;
  text-align: left;
}


/*styles for Header*/
.header-gold {
  background-color: #cf964c;
}

.header-text {
  color: #06400e;
  padding: 10px 20px 0px 0px;
}

.header-content {
  padding-left: 10px;
  padding-right: 10px;
}

.btn-logout {
  color: white;
  background-color: rgb(197, 19, 19);
  border-color: rgb(197, 19, 19);
  border-radius: 3px;
  padding: 5px;
}

.btn-logout:hover {
  background-color: rgb(240, 7, 7);
}



/*styles for Carousel*/
.carousel-container .carousel .carousel-item.active::before,
.carousel-container .carousel .carousel-item.active::after {
  transform: scale(0);
}

.carousel img {
    object-fit: cover; /* Set object-fit to cover */
    width: 100%;
    height: 90vh;
    object-position: center top;
  }

.play-pause-button {
    border: none;
    background-color: transparent;
    font-size: 40px;;
    font-weight: bolder;
    position: absolute;
    top: -10px; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center both vertically and horizontally */
}


/*common styles for Team and Menu*/
.close-add-new {
  background-color: red;
  border: red;
  color: white;
  border-radius: 2px;
  width: 30px;
  padding-bottom: 5px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
}

.close-add-new:hover {
background-color: rgb(231, 5, 5);
}

.add-new {
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px 30px 20px;
  font-family: 'Times New Roman', Times, serif;
  color: #06400e;
}

.add-new h2 {
  text-align: center;
  padding-bottom: 15px;
}

.btn-add-new {
  position: relative;
  border: transparent;
  background-color: transparent;
  color: #06400e;
  padding: 5px;
}

.btn-add-new::before,
.btn-add-new::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 1px; 
  background-color: #06400e; 
  left: 0;
  transition: all 0.1s;
  transform: scaleX(1); 
  transform-origin: center;
}

.btn-add-new::before{
  top: -2px;
}

.btn-add-new::after {
  bottom: -2px;
}

.btn-add-new:hover::before,
.btn-add-new:hover::after {
  transform: scaleX(0);
}

.btn-add-new:hover{
  color: #ebb267;
  background-color: #06400e;
  border-radius: 3px;
}



