/*styles for Add User*/

.close-add-user {
    background-color: red;
    border: red;
    color: white;
    border-radius: 2px;
    width: 30px;
    padding-bottom: 5px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
}

.close-add-user:hover {
  background-color: rgb(231, 5, 5);
}

.add-user {
    width: 100%;
    margin: 0 auto;
    padding: 40px 20px 30px 20px;
    font-family: 'Times New Roman', Times, serif;
    color: #06400e;
}

.add-user .error {
  color: red;
}

.add-user h2 {
    text-align: center;
}

.reg-password {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }
  
  .show-reg-password-button {
    position: absolute;
    top: 50%; 
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  .btn-register {
    position: relative;
    border: transparent;
    background-color: transparent;
    color: #06400e;
    padding: 5px;
  }

  .btn-register::before,
  .btn-register::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px; 
    background-color: #06400e; 
    left: 0;
    transition: all 0.1s;
    transform: scaleX(1); 
    transform-origin: center;
  }

  .btn-register::before{
    top: -2px;
  }

  .btn-register::after {
    bottom: -2px;
  }

  .btn-register:hover::before,
  .btn-register:hover::after {
    transform: scaleX(0);
  }
  
  .btn-register:hover{
    color: #ebb267;
    background-color: #06400e;
    border-radius: 3px;
  }


  /*styles for Manage users*/
  .view-users {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
    color: #06400e;
  }

  
  .view-users table {
    width: 100%;
    table-layout: fixed;
    text-align: center;
  }

  .view-users th, .view-users td {
    white-space: normal;
    overflow-wrap: break-word;
    padding: 8px 0px 8px 0px;
  }

  .view-users th:nth-child(1),
  .view-users th:nth-child(2),
  .view-users th:nth-child(3),
  .view-users th:nth-child(4),
  .view-users td:nth-child(1),
  .view-users td:nth-child(2),
  .view-users td:nth-child(3),
  .view-users td:nth-child(4) {
        width: 20%; /* Adjust the width as needed */
    }

  .view-users th:last-child,
  .view-users td:last-child {
    width: 20%;
  }

  .view-users .btn-new-user {
    padding: 10px 5px 10px 5px;
  }

  .view-users .btn-new-user button {
    background-color: #04500e;
    color: #ebb267;
    border: #06400e;
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
  }

  .view-users .btn-new-user button:hover {
    background-color: #06400e;
  }

  .user-list button {
    margin: auto 5px auto 5px;
    width: 40px;
    color: #ebb267;
    border-radius: 3px;
  }

  .user-list .changerole {
    background-color: rgb(2, 2, 80);
    border: rgb(0, 0, 37);
  }

  .user-list .removeuser {
    background-color: rgb(202, 5, 5);
    border: rgb(44, 1, 1);
  }


/*medium - larger tablets and smaller desktops*/
@media (max-width: 992px) {

}

/*small -tablets*/
@media (max-width: 768px) {

}

/*extra small - mobile devices*/
@media (max-width: 576px) {
    .view-users {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .view-users th, .view-users td {
        width: 33.33%;
        padding-left: 2px;
        padding-right: 2px;
    }

}