.login {
    display: inline-block;
    position: relative;
}

.login button {
    border: #06400e;
    background-color: #06400e;
}

.login .btn-login-form {
    color: #cf964c;
    font-family: 'Times New Roman', Times, serif;
    font-size: larger;
    position: relative;
}

.login .btn-login-form::before,
.login .btn-login-form::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #cf964c; 
    left: 0;
    transform: scaleX(1);
    transform-origin: center;
}

.login .btn-login-form::before {
    top: -2px;
}

.login .btn-login-form::after {
    bottom: -2px;
}

.login .btn-login-form:hover {
    background-color: #cf964c;
    color: #06400e;
    border-radius: 5px;
}

.login .btn-login-form:hover:before,
.login .btn-login-form:hover:after {
    transform: scaleX(0);
}

.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.login-popup {
    background-color: #06400e;
    border-radius: 8px;
    width: 400px;
    padding: 10px;
    margin: 10px;
}

.login-popup .close {
    background-color: red;
    color: white;
    border-radius: 2px;
    width: 30px;
    padding-bottom: 5px;
    cursor: pointer;
}

.login-content {
    padding: 10px 30px 20px 30px;
    font-family: 'Times New Roman', Times, serif;
    color:#cf964c;
}

.login-content h3 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.login-content .form-label {
    font-size: 20px;
}

.login-content button {
    border: #06400e;
    background-color: #06400e;
    margin-top: 10px;
}

.login-content .btn-login {
    color: #cf964c;
    font-family: 'Times New Roman', Times, serif;
    font-size: larger;
    position: relative;
}

.login-content .btn-login::before,
.login-content .btn-login::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #cf964c; 
    left: 0;
    transform: scaleX(1);
    transform-origin: center;
}

.login-content .btn-login::before {
    top: -2px;
}

.login-content .btn-login::after {
    bottom: -2px;
}

.login-content .btn-login:hover {
    background-color: #cf964c;
    color: #06400e;
    border-radius: 5px;
}

.login-content .btn-login:hover:before,
.login-content .btn-login:hover:after {
    transform: scaleX(0);
}

.log-password {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .log-password input {
    flex: 1;
  }
  
  .log-password button{
    background-color: transparent;
    color: #06400e;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 5px; /* Adjust the right position as needed */
    top: 25%;
    transform: translateY(-50%);
    
  }

  .link-login {
    font-size: larger;
  }

  .link-login:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .login-error {
    color: rgb(250, 51, 51);
  }
  
  
  
  
  

  




